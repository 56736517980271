import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { osName, getUA, browserName } from "react-device-detect";
// utils
import axios from "../utils/axios";
import { API_PATH } from "../utils/apis";
import { setSession, setHeader } from "../utils/jwt";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isVerifyEmail: false,
  isVerifyPhone: false,
  isPopupOpen: false,
  user: {},
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, isVerifyEmail, isVerifyPhone, user } =
      action.payload;

    return {
      ...state,
      isAuthenticated,
      isVerifyEmail,
      isVerifyPhone,
      isInitialized: true,
      isPopupOpen: false,
      user,
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user, isVerifyEmail, isVerifyPhone } = action.payload;
    return {
      ...state,
      isVerifyEmail,
      isVerifyPhone,
      isAuthenticated: true,
      isPopupOpen: false,
      user,
    };
  },
  LOGOUT: (state, action) => {
    const { isPopUp } = action.payload;
    return {
      ...state,
      isAuthenticated: false,
      isVerifyEmail: false,
      isVerifyPhone: false,
      isPopupOpen: isPopUp,
      user: {},
    };
  },
  VERIFYEMAIL: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isVerifyEmail: true,
      user,
    };
  },
  VERIFYPHONE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isVerifyPhone: true,
      user,
    };
  },
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isVerifyEmail: false,
      isVerifyPhone: false,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});
AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = async () => {
    setHeader();
    let isAuthenticated = false;
    let isVerifyEmail = false;
    let isVerifyPhone = false;
    let user = {};
    try {
      const response = await axios.get(API_PATH.profile);
      const { status, data } = response.data;
      user = data;
      if (status === "success") {
        const { profile } = user;
        isAuthenticated = Boolean(profile.uid);
        // eslint-disable-next-line
        if (isAuthenticated && parseInt(profile.isVerifiedPhone, 10) === 1) {
          isVerifyPhone = true;
        }
        if (isAuthenticated && parseInt(profile.isVerifiedEmail, 10) === 1) {
          isVerifyEmail = true;
        }
      }

      if (
        status === "userid_expired" ||
        status === "userid_error" ||
        status === "invalid_profile"
      ) {
        isAuthenticated = false;
        setSession(null);
      }
    } catch (err) {
      // null
    }
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated,
        isVerifyEmail,
        isVerifyPhone,
        user,
      },
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  const logout = async (isPopUp) => {
    if (isPopUp) {
      const currentUser = state.user.user;
      localStorage.setItem(
        "popupName",
        (currentUser && currentUser.firstName) || ""
      );
      localStorage.setItem(
        "popupEmail",
        (currentUser && currentUser.email) || ""
      );
    } else {
      localStorage.removeItem("popupName");
      localStorage.removeItem("popupEmail");
    }
    logDevice(0);
    setSession(null);
    dispatch({ type: "LOGOUT", payload: { isPopUp } });
  };
  const login = async (email, password, accountType) => {
    const response = await axios.post(API_PATH.login, {
      email,
      password,
      method: "email",
      accountType: accountType === "2" ? "1" : accountType,
    });
    const { status, data } = response.data;
    if (status === "success") {
      const { token, profile } = data;
      setSession(token);
      const isVerifyEmail = parseInt(profile.isVerifiedEmail, 10) === 1;
      const isVerifyPhone = parseInt(profile.isVerifiedPhone, 10) === 1;
      logDevice();
      dispatch({
        type: "LOGIN",
        payload: { user: data, isVerifyEmail, isVerifyPhone },
      });
      return true;
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been suspended! Please contact support for more information."
      );
    }
    throw new Error(
      "Invalid email address or password. Please confirm and try again."
    );
  };
  const signupAccount = async (values, bvn, dob) => {
    const response = await axios.post(API_PATH.signupAccount, {
      ...values,
      bvn,
      dob,
    });

    const { status, data } = response.data;
    if (status === "success") {
      const { token, profile } = data;
      setSession(token);
      const isVerifyEmail = parseInt(profile.isVerifiedEmail, 10) === 1;
      const isVerifyPhone = parseInt(profile.isVerifiedPhone, 10) === 1;
      logDevice();
      dispatch({
        type: "LOGIN",
        payload: { user: data, isVerifyEmail, isVerifyPhone },
      });
      return true;
    }
    if (status === "email_exists") {
      throw new Error(
        "The email address supplied is already in use. Please login to your account or use another email address to proceed."
      );
    }
    if (status === "phone_exists") {
      throw new Error(
        "The phone number supplied is already in use. Please login to your account or use another phone number to proceed."
      );
    }
    if (status === "bvn_error") {
      throw new Error(
        "An error occurred while attempting to verify your BVN. Please confirm your BVN details to proceed."
      );
    }
    throw new Error(
      "An error occurred while attempting to create your account. Please check your internet connection and confirm your details to proceed."
    );
  };
  const signupBVN = async (values) => {
    const response = await axios.post(API_PATH.signupBvn, {
      ...values,
      accountType: values.accountType === "2" ? "1" : values.accountType,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "invalid_date_of_birth") {
      throw new Error(
        "The Date of birth supplied does not match the BVN provided. Please confirm your BVN details to proceed."
      );
    }
    if (status === "bvn_otp_error") {
      throw new Error(
        "The OTP you entered is invalid! Please confirm and try again."
      );
    }
    if (status === "bvn_name_error") {
      return status;
    }
    if (status === "bvn_exists") {
      throw new Error(
        "The BVN provided has already been signed up. Please login to your account to proceed."
      );
    }
    throw new Error(
      "An error occurred while attempting to verify your BVN. Please check your internet connection and confirm your BVN details to proceed."
    );
  };
  const logDevice = async () => {
    axios.post(API_PATH.saveDevice, {
      name: browserName,
      os: osName,
      deviceId: getUA,
      type: 0,
      token: "",
    });
  };

  const resetPassword = async (code, password) => {
    const response = await axios.post(API_PATH.resetPassword, {
      code,
      password,
    });
    const { status } = response.data;
    if (status !== "success") {
      if (status === "code_expired") {
        throw new Error(
          "The code you entered has expired! Please request a new one and try again."
        );
      } else if (status === "code_used") {
        throw new Error(
          "The code you entered has been used! Please request a new one and try again."
        );
      } else if (status === "code_invalid") {
        throw new Error(
          "The code you entered is invalid! Please request a new one and try again."
        );
      } else {
        throw new Error(
          "An error occurred while attempting to send your OTP. Please check your internet connection and try again."
        );
      }
    }
  };
  const forgotPassword = async (email, accountType) => {
    const response = await axios.post(API_PATH.forgotPassword, {
      email,
      accountType: accountType === "2" ? "1" : accountType,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while attempting to request password reset. Please verify your email address is correct and try again."
      );
    }
  };
  const saveToken = async (saveToken) => {
    await axios.post(API_PATH.notifications, { saveToken, device: getUA });
  };
  const verifyEmailAccount = async (code) => {
    const response = await axios.post(API_PATH.verifyAccount, { code });
    const { status } = response.data;
    if (status !== "success") {
      if (status === "code_expired") {
        throw new Error(
          "The code you entered has expired. Please request another to proceed."
        );
      } else if (status === "code_invalid") {
        throw new Error(
          "The code you entered is invalid. Please request another to proceed."
        );
      } else if (status === "code_used") {
        throw new Error(
          "The code you entered has already been used. Please request another to proceed."
        );
      } else {
        throw new Error(
          "An error occurred while attempting to verify your email address. Please check your internet connection try again later."
        );
      }
    } else {
      await initialize();
    }
  };
  const sendVerifyEmailCode = async () => {
    const response = await axios.get(`${API_PATH.sendVerifyAccount}?type=1`);
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while attempting to send your OTP. Please check your internet connection and try again."
      );
    }
  };
  const verifyPhoneAccount = async (code) => {
    const response = await axios.post(API_PATH.verifyAccount, { code });
    const { status } = response.data;
    if (status !== "success") {
      if (status === "code_expired") {
        throw new Error(
          "The code you entered has expired. Please request another to proceed."
        );
      } else if (status === "code_invalid") {
        throw new Error(
          "The code you entered is invalid. Please request another to proceed."
        );
      } else if (status === "code_used") {
        throw new Error(
          "The code you entered has already been used. Please request another to proceed."
        );
      } else {
        throw new Error(
          "An error occurred while attempting to verify your phone number. Please check your internet connection try again later."
        );
      }
    } else {
      await initialize();
    }
  };
  const sendVerifyPhoneCode = async () => {
    const response = await axios.get(`${API_PATH.sendVerifyAccount}?type=0`);
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while attempting to send your OTP. Please check your internet connection and try again."
      );
    }
  };
  const sendPasswordVerify = async () => {
    const { user } = state;
    const { usertype } = user.user;
    const uid = parseInt(usertype, 10) === 1 ? user.user.lid : user.user.uid;
    const response = await axios.post(API_PATH.profile, {
      generate_set_password: uid,
      type: usertype,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "We cannot send your set password code at the moment! Please try again later."
      );
    }
  };
  const updateSettings = async (values) => {
    const response = await axios.post(API_PATH.profile, values);
    const { status } = response.data;
    if (status === "success") {
      initialize();
    }
    if (status === "error") {
      throw new Error("omething went wrong. Please try again later.");
    }
    if (status === "invalid_user") {
      logout();
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been placed on hold! Please contact support."
      );
    }
  };
  const updateSettingsVerification = async (values) => {
    const response = await axios.post(API_PATH.setVerification, {
      ...values,
      idType: values.idType.code,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error("Something went wrong. Please try again later.");
  };
  const updateSettingsPassword = async (values) => {
    const response = await axios.post(API_PATH.password, values);
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error(
      "Something went wrong. Please check your password and  try again later."
    );
  };
  const getBanks = async () => {
    const response = await axios.get(API_PATH.banks);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getAccountName = async (bank, number) => {
    const response = await axios.post(API_PATH.verifyBankAccount, {
      bank_code: bank,
      account_number: number,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "We cannot verify your bank account information! Please confirm it is accurate and try again."
    );
  };
  const updateBVN = async (values) => {
    const response = await axios.post(API_PATH.setBVN, {
      ...values,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error(
      "There is an issue with your BVN information! Please check your email for more information."
    );
  };
  const updateSettingsEmployment = async (values) => {
    const response = await axios.post(API_PATH.employment, {
      ...values,
      employmentType: values.employmentType.label || "",
      workStartDate: values.workStartDate.label || "",
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
    }
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating employment information! Please try again later."
      );
    }
  };
  const resendWorkOTP = async () => {
    const response = await axios.get(API_PATH.sendVerifyWorkEmail);
    const { status } = response.data;
    if (status === "success") {
      return true;
    }
    throw new Error(
      "An error occurred while attempting to send your OTP. Please check your work email address is valid and try again."
    );
  };
  const verifyWorkEmail = async (code) => {
    const response = await axios.post(API_PATH.verifyWorkMail, {
      code,
    });
    const { status } = response.data;
    if (status !== "success") {
      if (status === "code_expired") {
        throw new Error(
          "The code you entered has expired. Please request another to proceed."
        );
      } else if (status === "code_invalid") {
        throw new Error(
          "The code you entered is invalid. Please request another to proceed."
        );
      } else if (status === "code_used") {
        throw new Error(
          "The code you entered has already been used. Please request another to proceed."
        );
      } else {
        throw new Error(
          "An error occurred while attempting to verify your email address. Please check your work email address is valid and try again."
        );
      }
    } else {
      initialize();
    }
  };
  const topupSavings = async (ref, amount, method, sid) => {
    const response = await axios.post(API_PATH.topupSavings, {
      amount,
      sid,
      method,
      ref,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error(
      "An error occurred while verifying your payment. Please try again later or contact support if you were debited."
    );
  };
  const cancelSaving = async (savingId) => {
    const response = await axios.post(API_PATH.cancelSavings, {
      savingId,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error("Something went wrong. Please try again later.");
  };
  const addWithdraw = async (values) => {
    const response = await axios.post(API_PATH.withdrawWallet, {
      ...values,
      reason: values.reason.code,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
    } else if (status === "invalid_account") {
      throw new Error(
        "No Withdrawal Account detected! Please set a valid account to proceed."
      );
    } else if (status === "amount_too_small") {
      throw new Error(
        `The withdrawal amount is lower than the minimum amount of $minAmount! Please set a valid amount to proceed.`
      );
    } else if (status === "insufficient_balance") {
      throw new Error(
        `You do not have enough balance in your wallet to withdraw.`
      );
    } else if (status === "interval_not_reached") {
      throw new Error(
        `You cannot request withdrawal at the moment! Please try again in 12 hours after your last withdrawal.`
      );
    } else if (status === "verification_error") {
      throw new Error(`Please complete your KYC before requesting withdrawal`);
    } else if (status === "invalid_password") {
      throw new Error(
        `Your password is incorrect! Please confirm and try again.`
      );
    } else {
      throw new Error(
        `Something went wrong. Please try again later or contact support if your wallet is debited.`
      );
    }
  };
  const addWithdrawAccount = async (values) => {
    const response = await axios.post(API_PATH.updateAccount, {
      bank_code: values.bank.code,
      account_number: values.accountNumber,
      bank_name: values.bank.name,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error(`Something went wrong. Please try again later.`);
  };
  const addLoan = async (values) => {
    const response = await axios.post(API_PATH.saveLoan, {
      loanCategoryId: values.category.id,
      amount: values.amount,
      dateOfRepayment: values.dateOfRepayment,
      dayOfRepayment: values.dayOfRepayment,
      durationType:
        values.duration.durationType === "months" ||
        values.duration.durationType === "month"
          ? "MONTHLY"
          : "YEARLY",
      loanDuration: values.duration.duration,
    });
    const { status, data } = response.data;
    if (status === "success") {
      initialize();
      return data.applicationNumber;
    }
    throw new Error(
      `An error occurred! We cannot add your loan request at the moment! Please try again later.`
    );
  };
  const submitLoan = async (loanId) => {
    const response = await axios.post(API_PATH.submitLoanCivil, {
      loan_id: loanId,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error(`Something went wrong. Please try again later.`);
  };
  const updateLoanStatus = async (loanId, stat) => {
    const response = await axios.post(API_PATH.updateLoanCivil, {
      loan_id: loanId,
      status: stat,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error(`Something went wrong. Please try again later.`);
  };
  const setDefaultAccount = async (bankId, accountNumber) => {
    const response = await axios.post(API_PATH.setDefaultAccount, {
      bankId,
      accountNumber,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error("Something went wrong. Please try again later.");
  };
  const setDefaultCard = async (bank, number, cardType) => {
    const response = await axios.post(API_PATH.setDefaultCard, {
      bank,
      number,
      cardType,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error("Something went wrong. Please try again later.");
  };
  const getRef = async () => {
    const response = await axios.get(API_PATH.getCardReference);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error("Something went wrong. Please try again later.");
  };
  const addCard = async (ref) => {
    const response = await axios.post(API_PATH.saveCards, {
      ref,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return true;
    }
    if (status === "card_exists") {
      throw new Error("This card has already been added!");
    }
    throw new Error("Something went wrong. Please try again later.");
  };
  const uploadWorkId = async (formData) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(API_PATH.saveWorkId, formData, config);
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return true;
    }
    throw new Error("Something went wrong. Please try again later.");
  };
  const uploadPayslip = async (formData) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(API_PATH.savePayslip, formData, config);
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return true;
    }
    throw new Error("Something went wrong. Please try again later.");
  };
  const uploadIdCard = async (formData) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(API_PATH.saveId, formData, config);
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error(`Something went wrong! Please try again later.`);
  };
  const getSaving = async (sid) => {
    const response = await axios.get(`${API_PATH.savingDetails}?saving=${sid}`);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      `An error occurred while fetching your saving details! Please try again later.`
    );
  };
  const getDefaults = async () => {
    const response = await axios.get(API_PATH.getDefaults);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getLoanCategories = async () => {
    const response = await axios.get(API_PATH.loansCategories);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getInvestTypes = async (uid) => {
    const response = await axios.get(
      `${API_PATH.getInvestmentsTypes}?uid=${uid}`
    );
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getInvestCategories = async () => {
    const response = await axios.get(API_PATH.getInvestmentsCategories);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };

  const fetchNetwork = async (type, code) => {
    const response = await axios.get(
      `${API_PATH.getBillNetworks}?type=${type}&code=${code}`
    );
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const fetchWithdrawalBanks = async () => {
    const response = await axios.get(`${API_PATH.getBillBeneficiaries}`);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line
      value={{
        ...state,
        method: "jwt",
        getInvestCategories,
        saveToken,
        login,
        signupBVN,
        signupAccount,
        logout,
        updateSettings,
        sendPasswordVerify,
        getBanks,
        getAccountName,
        forgotPassword,
        resetPassword,
        updateSettingsEmployment,
        addLoan,
        sendVerifyEmailCode,
        verifyEmailAccount,
        sendVerifyPhoneCode,
        verifyPhoneAccount,
        updateBVN,
        initialize,
        getDefaults,
        updateSettingsVerification,
        updateSettingsPassword,
        verifyWorkEmail,
        resendWorkOTP,
        cancelSaving,
        addWithdraw,
        getLoanCategories,
        addWithdrawAccount,
        getInvestTypes,
        uploadIdCard,
        fetchNetwork,
        fetchWithdrawalBanks,
        addCard,
        getRef,
        setDefaultAccount,
        setDefaultCard,
        uploadWorkId,
        uploadPayslip,
        getSaving,
        topupSavings,
        submitLoan,
        updateLoanStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
